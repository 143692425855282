import React, { useState, useEffect } from "react";
import axios from "axios";
import Marquee from "react-fast-marquee";

const News = () => {
  const [news, setNews] = useState([]);
  const [newsId, setNewsId] = useState("");
  const [newsName, setNewsName] = useState("");
  const [description, setDescription] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const headers = {
    "Content-type": "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbjEiLCJpYXQiOjE2NDgwMzE5OTUsImV4cCI6MTY2MzU4Mzk5NX0.h3uex4gcdMAA8cOMN_mqFbxlYhZRtFkQMLoke7y-8dXL0-oBy41qmWhkIJ-O0bNlaLcdHYxKygm3GHdd84bMVw",
  };

  const baseUrl = "https://executivetracking.cloudjiffy.net/SRPUSchoolWeb";

  const NewData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/news/v1/getAllNewsByPagination/{pageNumber}/{pageSize}?pageNumber=0&pageSize=5`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        console.log(res.data);
        setNews(data.content);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    NewData();
  }, [newsName, newsId, description, pageSize, pageNumber]);

  return (
    <div>
      <Marquee>
        {news.map((data) => {
          return <h4 className="news-ticker">{data.newsName}</h4>;
        })}
        <h4 className="news-ticker">
        Admissions open for Science for the academic year 2025-2026
        </h4>
        {/* <h4 className="news-ticker">
          We are Hiring !!!! Lecturers for PUC SCIENCE(PCMBCs) and
          COMMERCE(HEBACs).
        </h4>
        <h4 className="news-ticker">
          PU 2 Board Exam 2023- 100% Result. Distinction: 60 , First Class: 52
        </h4>
        <h4 className="news-ticker">
          Pleased to announce our tie up with Aakash
        </h4>
        <h4 className="news-ticker">
          Entrance exam on Sunday, January 21, 2024 at centres Sanganabasava
          Shishuniketan School, NH-13. Solapur Bypass Road,Vijayapur and Atal
          Bihari Vajpayee School, Court Colony, Athani Road, Vijayapura.
        </h4>
        <h4 className="news-ticker">
          You may also walk-in on any day (including Sundays) for writing
          entrance exam at Kavalagi campus.
        </h4>
        <h4 className="news-ticker">
          Immediate openings for Physics(JEE level), Biology, (NEET level) and
          mathematics (Karnataka board level). Salary commensurate with
          competence and experience. walk - in for interview.
        </h4> */}
      </Marquee>
    </div>
  );
};

export default News;
