import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHead } from "react-bootstrap";
import DR_RABINCHANDRA from "../../../assets/img/Staffs/ScienceStaffs/DR_RABINCHANDRA.JPG";
import K_T_NAGARAJ from "../../../assets/img/Staffs/ScienceStaffs/K_T_NAGARAJ.JPG";
import MAHANTESH from "../../../assets/img/Staffs/ScienceStaffs/MAHANTESH.JPG";
import MAHESH from "../../../assets/img/Staffs/ScienceStaffs/MAHESH.JPG";
import MALLAPPA from "../../../assets/img/Staffs/ScienceStaffs/MALLAPPA.JPG";
import NUZHAT from "../../../assets/img/Staffs/ScienceStaffs/NUZHAT.JPG";
import PAAPI_REDDY from "../../../assets/img/Staffs/ScienceStaffs/PAAPI_REDDY.jpg";
import PRASHANT from "../../../assets/img/Staffs/ScienceStaffs/PRASHANT.JPG";
import S_S_SALOTAGI from "../../../assets/img/Staffs/ScienceStaffs/S_S_SALOTAGI.JPG";
import SHIVAYOGI from "../../../assets/img/Staffs/ScienceStaffs/SHIVAYOGI.JPG";
import SEEMA from "../../../assets/img/Staffs/ScienceStaffs/SEEMA.jpg";
import SHASHIKUMAR from "../../../assets/img/Staffs/ScienceStaffs/SHASHIKUMAR.JPG";
import SARASWATI from "../../../assets/img/Staffs/ScienceStaffs/SARASWATI.JPG";
import SARJI_RAO from "../../../assets/img/Staffs/ScienceStaffs/SARJI_RAO.JPG";
import SIDDANAGOUDA from "../../../assets/img/Staffs/ScienceStaffs/SIDDANAGOUDA.JPG";
import SHARANAMMA from "../../../assets/img/Staffs/ScienceStaffs/SHARANAMMA.JPG";
import RAMACHANDRA from "../../../assets/img/Staffs/ScienceStaffs/RAMACHANDRA.JPG";
import MANZOOR from "../../../assets/img/Staffs/ScienceStaffs/MANZOOR.JPG";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const ScienceStaffs = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Streams</li>
          </ol>
          <h2>SCIENCE STREAM STAFF</h2>
        </div>
      </section>

      {/* <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <h3>PRINICIPAL</h3>
            <div class="post-box">
              <div class="post-img">
                <img src={DR_RABINCHANDRA} class="img-fluid center" alt="" />
              </div>
              <h5 class="post-title">Dr. RABIN CHANDRA PARAMANIK </h5>
              <span class="post-date">
                M.Sc,M.Phil,Ph.D,F.S.Sc,F.S.E.Sc,F.I.S.E.C,
                F.I.S.B.T.F.I.S.C.A.,D.Litt(U.S.A),(D.Sc).,P.M.I.R&L.W
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <h4>Principal’s message:</h4>
            <br />
            <p className="text-muted-quote">
              <strong>
                {" "}
                “ Education is the most powerful weapon which you can use to
                change the World ” - Nelson Mandela.
              </strong>
              <br />
              <strong>
                {" "}
                “ Where Dreams take wings and aspirations are elevated to a
                status of reality ”
              </strong>
              <br />
              <strong>
                {" "}
                " Transforming ransforming the insitutional mission to the
                mission / fruit of success."
              </strong>
              <br />
            </p>
            <p className="text-muted-message">
              Is there a difference between Land and Nation? House and Home? Boy
              and Son? Yes! There is difference between PU College and
              Sanganabasava Residential PU College, Kavalagi, Vijayapur. Aren’t
              we proud of our nation for UNITY IN DIVERSITY? We enjoy numerous
              festivals, celebrations, cultural occasions, spirituality & our
              family everything that emotionally unite us to experience peace
              and happiness. No other country is blessed with the culture we
              Indians have. But LOOK “What is TV showing our children?”, “What
              do they do on internet?” What do you expect him to learn? And what
              do you think they will actually learn? What is our Future? Along
              with International Standard Educational services, Sanganabasava
              Residential PU College, Kavalagi, Vijayapur. Answers all these
              questions with its dedicated efforts in inculcating Moral Values &
              Spirituality along with world class Education in its students -{" "}
              <strong>Your Child. You’re FUTURE!</strong>
            </p>
          </div>
        </div>
      </section> */}
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF PHYSICS</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SIDDANAGOUDA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Siddanagouda.R.T </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHARANAMMA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Ms. Sharanamma. H </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={RAMACHANDRA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Ramachandra </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF CHEMISTRY</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={S_S_SALOTAGI} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Shivanand Salotagi </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHIVAYOGI} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Shivayogi </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MAHESH} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Mahesh Gurav </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF MATHEMATICS</h5>
                </Card.Header>
              </Card>
            </header>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={K_T_NAGARAJ} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. K.T.Nagaraj </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PAAPI_REDDY} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Papi Reddy </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF BIOLOGY</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={NUZHAT} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Ms. Nuzhat Kazi </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SEEMA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Ms. Seema </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF COMPUTER SCIENCE</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PRASHANT} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Prashant Patil</h5>
                <span class="post-date fas fa-graduation-cap"> B.E. , C.S</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5> DEPARTMENT OF LANGUAGES</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MALLAPPA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Mallappa Jaggal </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.A. , B.Ed
                </span>
                <span class="post-date-name">KANNADA - LECTURER</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MAHANTESH} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Mahantesh </h5>
                <span class="post-date fas fa-graduation-cap"> M.A , B.Ed</span>
                <span class="post-date-name">ENGLISH - LECTURER</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>LIBRARIAN</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SARASWATI} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Ms. Saraswati Madar</h5>
                <span class="post-date fas fa-graduation-cap"> M.Lib</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>SPORTS DEPARTMENT</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SARJI_RAO} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Sarjirao Chavan</h5>
                <span class="post-date fas fa-graduation-cap"> M.P.Ed</span>
                <span class="post-date-name">PHYSICAL DIRECTOR</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>ADMINISTRATIVE OFFICER</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MANZOOR} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Manzoor Wahidi</h5>
                <span class="post-date fas fa-graduation-cap"> MBA</span>
                <span class="post-date-name">ADMIN OFFICER</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};
export default ScienceStaffs;
