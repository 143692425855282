import React from "react";
import NavBar from "../../home/home components/NavBar";
import { Link } from "react-router-dom";
import Footer from "../../home/home components/Footer";
import Floats from "../../home/home components/Floats";
import { Button, Card } from "react-bootstrap";
import "./Results.css";
import newgif from "../../../assets/img/new.gif";
import result from "../../../assets/img/result 2023.pdf";
import result1 from "../../../I PUC RESULT ANALYSIS - 2023-2024.pdf";
import result2 from "../../../I PUC RESULT ANALYSIS - 2024 Photos With Color Background.pdf";
import result3 from "../../../II PUC RESULT ANALYSIS - 2023-24..pdf";
import result4 from "../../../II PUC RESULT ANALYSIS - 2024 Photos With Color Background new format.pdf";
import result5 from "../../../II PUC RESULT Subject wise ANALYSIS - 2023-24 - Color.pdf";
import result6 from "../../../II PUC RESULT Subject wise ANALYSIS scorer name - 2023-24.pdf";
import image from "../../../images/qualification jee.jpeg";

const Result = () => {
  return (
    <React.Fragment>
      <NavBar />
      {/* <!------- End of Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Results</li>
          </ol>
          <h2>Results</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      <section id="portfolio" class="portfolio">
        <div class="container">
          <header class="section-header">
            <Card className="text-center">
              <Card.Header>
                <h4>Results</h4>
              </Card.Header>
            </Card>
          </header>
          <div className="blink-container"> 

          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                />
                <a href={image}  >
                <Link to="/viewresult">
                  <Button>
                    <h4>I PUC ANNUAL RESULT 2025</h4>
                  </Button>
                  </Link>
                </a>
              </span>
            </div>
          </div>
          <br/>
          <br/>
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={image} download="result" target="_blank">
                  <Button>
                    <h4> JEE MAINS RESULT Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>

          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result3} download="result" target="_blank">
                  <Button>
                    <h4>II PUC RESULT ANALYSIS(2023-24) Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result4} download="result" target="_blank">
                  <Button>
                    <h4> TOPPERS OF II PUC EXAMINATION(2023-24) Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result5} download="result" target="_blank">
                  <Button>
                    <h4>
                      {" "}
                      SUBJECT WISE RESULT ANALYSIS OF II PUC
                      EXAMINATION(2023-24) Download{" "}
                    </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result6} download="result" target="_blank">
                  <Button>
                    <h4>
                      {" "}
                      TOPPER NAME SUBJECT WISE RESULT ANALYSIS OF II PUC
                      EXAMINATION (2023-24)  Download{" "}
                    </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result1} download="result" target="_blank">
                  <Button>
                    <h4>I PUC RESULT ANALYSIS (2023-24) Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span
                className="blink"
                style={{ position: "relative", display: "inline-block" }}
              >
                {/* <img
                  src={newgif}
                  alt="New"
                  style={{ position: "absolute", top: "-25px", left: "-15px" }}
                /> */}
                <a href={result2} download="result" target="_blank">
                  <Button>
                    <h4>I PUC RESULT Topper Photos (2023-24) Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <span className="blink">
                <a href={result} download="result" target="_blank">
                  <Button>
                    <h4>II PUC RESULT ANALYSIS (2022-23) Download </h4>
                  </Button>
                </a>
              </span>
            </div>
          </div>
          </div>
        </div>
      </section>
      <div></div>
      <Footer />
      <Floats />
    </React.Fragment>
  );
};

export default Result;
