
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import HomePage from './components/home/home components/HomePage';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Swami from './components/pages/about/Swami';
import College from './components/pages/about/College';
import VisionandMission from './components/pages/about/VisionandMission';
import PresidentMessage from './components/pages/about/PresidentMessage';
import BoardOfDirectors from './components/pages/about/BoardOfDirectors';
import ChairmanMessage from './components/pages/about/ChairmanMessage';
import CAOMessage from './components/pages/about/CAOMessage';
import Facility from './components/pages/Infrastructure/Facility';
import ScienceStaffs from './components/pages/SRPU/ScienceStaffs';
import CommerceStaffs from './components/pages/SRPU/CommerceStaffs';
import PrincipalMessage from './components/pages/about/PrincipalMessage';
import Lab from "./components/pages/Infrastructure/laboratories";
import Sports from "./components/pages/Infrastructure/sports";
import Libraries from "./components/pages/Infrastructure/Libraries";
import Hostel from "./components/pages/Infrastructure/Hostel";
import SRPU from "./components/pages/SRPU/SRPU";
import Annapoorna from "./components/pages/Infrastructure/Annapoorna";
import Gallery from "./components/pages/media/Gallery";
import Notification from "./components/pages/Notification/Notification";
import Result from './components/pages/Results/Result';
import samplePDF from "./result.pdf";
import ViewResult from './components/pages/Results/ViewResult';

function App() {
  return (
    <div>  
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage/>}></Route>
          <Route path="/about" element={<About/>}></Route>

          {/* About Pages Routing */}
          <Route path="/swami" element={<Swami/>}/> 
          <Route path="/college" element={<College/>}/>
          <Route path="/vissionandmission" element={<VisionandMission/>} />
          <Route path="/presidentmessage" element={<PresidentMessage/>} />
          <Route path="/chairmanmessage" element={<ChairmanMessage/>} />
          <Route path="/caomessage" element={<CAOMessage/>} />
          <Route path="/principalmessage" element={<PrincipalMessage/>} />
          <Route path="/boardofdirectors" element={<BoardOfDirectors/>} />

          {/* SRPUExperience Pages Routing */}
          <Route path="/srpu" element={<SRPU/>}/>
          {/* <Route path="/sciencestaffs" element={<ScienceStaffs/>}/> */}
          <Route path="/commercestaffs" element={<CommerceStaffs/>}/>
          {/* Infrastructure Pages */}
          <Route path="/infrastructure" element={<Facility/>}/>
          <Route path="/laboratories" element={<Lab/>} />
          <Route path="/Sports" element={<Sports/>}/>
          <Route path="/Libraries" element={<Libraries/>}/>
          <Route path="/Hostel" element={<Hostel/>}/>
          <Route path="/Annapoorna" element={<Annapoorna/>}/>

          {/* Media pages */}
          <Route path="/gallery" element={<Gallery/>}/>

          {/* Notification pages */}
          <Route path="/notification" element={<Notification/>}/>

           {/* Results pages */}
           <Route path="/result" element={<Result/> }/>
           <Route path="/viewresult" element={<ViewResult/>}/>

          {/* Contact page */}
          <Route path="/contact" element={<Contact/>}></Route>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
