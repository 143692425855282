import React from "react";

const ContactHome = () => {
  return (
    <div>
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" class="contact">
        <div class="container">
          <header class="section-header">
            <p>Contact Us</p>
          </header>

          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="fas fa-map-marker-alt"></i>
                    <h3>Address</h3>
                    <p>
                      Sanganabasava Residential PU college for Science and
                      Commerce,
                      <br />
                      kavalagi Post Honnutagi District, Vijayapura, Karnataka –
                      586127
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box mt-4">
                    <i class="fas fa-phone-volume"></i>
                    <h3>Call Us </h3>
                    <p>
                      {/* +91 8660839779
                      <br /> */}
                      +91 9880608303 <br /> 
                      +91 9113281526
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box mt-4">
                    <i class="fas fa-envelope-open-text"></i>
                    <h3>Email Us</h3>
                    <p>srpucollege.kavalagi@gmail.com</p>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <form
                action="forms/contact.php"
                method="post"
                class="php-email-form"
              >
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div class="col-md-6 ">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactHome;
