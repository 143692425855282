import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardGroup,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Navbar,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { Card as BootstrapCard } from "react-bootstrap";
import "./Gallery.css";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import axios from "axios";
import world1 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0530.JPG";
import world2 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0532.JPG";
import world3 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0535.JPG";
import world4 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0538.JPG";
import world5 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0548.JPG";
import world6 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0554.JPG";
import world7 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0559.JPG";
import world8 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0564.JPG";
import world9 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0567.JPG";
import world10 from "../../../images/1.WORLD ENVIRONMENT DAY/_DSC0574.JPG";
import sara1 from "../../../images/2.saraswati pooja & Orientation program/DSC_0759.JPG";
import sara2 from "../../../images/2.saraswati pooja & Orientation program/DSC_0793.JPG";
import sara3 from "../../../images/2.saraswati pooja & Orientation program/DSC_0803.JPG";
import sara4 from "../../../images/2.saraswati pooja & Orientation program/DSC_0824.JPG";
import sara5 from "../../../images/2.saraswati pooja & Orientation program/DSC_0837.JPG";
import sara6 from "../../../images/2.saraswati pooja & Orientation program/DSC_0854.JPG";
import sara7 from "../../../images/2.saraswati pooja & Orientation program/DSC_0901.JPG";
import sara8 from "../../../images/2.saraswati pooja & Orientation program/DSC_0919.JPG";
import sara9 from "../../../images/2.saraswati pooja & Orientation program/DSC_0921.JPG";
import sara10 from "../../../images/2.saraswati pooja & Orientation program/DSC_0932.JPG";
import yoga1 from "../../../images/3.YOGA DAY/_DSC0132.JPG";
import yoga2 from "../../../images/3.YOGA DAY/_DSC0133.JPG";
import yoga3 from "../../../images/3.YOGA DAY/_DSC0146.JPG";
import yoga4 from "../../../images/3.YOGA DAY/_DSC0152.JPG";
import yoga5 from "../../../images/3.YOGA DAY/_DSC0159.JPG";
import yoga6 from "../../../images/3.YOGA DAY/_DSC0169.JPG";
import yoga7 from "../../../images/3.YOGA DAY/_DSC0181.JPG";
import yoga8 from "../../../images/3.YOGA DAY/_DSC0215.JPG";
import yoga9 from "../../../images/3.YOGA DAY/_DSC0225.JPG";
import yoga10 from "../../../images/3.YOGA DAY/_DSC0232.JPG";
import fresher1 from "../../../images/4.FRESHERS day/_DSC0320.JPG";
import fresher2 from "../../../images/4.FRESHERS day/_DSC0324.JPG";
import fresher3 from "../../../images/4.FRESHERS day/_DSC0331.JPG";
import fresher4 from "../../../images/4.FRESHERS day/DSC_0526.JPG";
import fresher5 from "../../../images/4.FRESHERS day/DSC_0606.JPG";
import fresher6 from "../../../images/4.FRESHERS day/DSC_0639.JPG";
import fresher7 from "../../../images/4.FRESHERS day/DSC_0707.JPG";
import fresher8 from "../../../images/4.FRESHERS day/DSC_0722.JPG";
import fresher9 from "../../../images/4.FRESHERS day/DSC_0731.JPG";
import fresher10 from "../../../images/4.FRESHERS day/DSC_0820.JPG";
import abvp1 from "../../../images/5.ABVP PROGRAM/_DSC0362.JPG";
import abvp2 from "../../../images/5.ABVP PROGRAM/_DSC0364.JPG";
import abvp3 from "../../../images/5.ABVP PROGRAM/_DSC0368.JPG";
import abvp4 from "../../../images/5.ABVP PROGRAM/_DSC0370.JPG";
import abvp5 from "../../../images/5.ABVP PROGRAM/_DSC0371.JPG";
import abvp6 from "../../../images/5.ABVP PROGRAM/_DSC0372.JPG";
import abvp7 from "../../../images/5.ABVP PROGRAM/_DSC0388.JPG";
import abvp8 from "../../../images/5.ABVP PROGRAM/_DSC0391.JPG";
import abvp9 from "../../../images/5.ABVP PROGRAM/_DSC0394.JPG";
import abvp10 from "../../../images/5.ABVP PROGRAM/_DSC0405.JPG";
import bday1 from "../../../images/6.Sanganabasava Swamiji Birthday/_DSC0031.JPG";
import bday2 from "../../../images/6.Sanganabasava Swamiji Birthday/_DSC0041.JPG";
import bday3 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0388.JPG";
import bday4 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0407.JPG";
import bday5 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0416.JPG";
import bday6 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0445.JPG";
import bday7 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0468.JPG";
import bday8 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0483.JPG";
import bday9 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0484.JPG";
import bday10 from "../../../images/6.Sanganabasava Swamiji Birthday/DSC_0528.JPG";
import ind1 from "../../../images/7.independence day/DSC_0821.JPG";
import ind2 from "../../../images/7.independence day/DSC_0848.JPG";
import ind3 from "../../../images/7.independence day/DSC_0859.JPG";
import ind4 from "../../../images/7.independence day/DSC_0864.JPG";
import ind5 from "../../../images/7.independence day/DSC_0867.JPG";
import ind6 from "../../../images/7.independence day/DSC_0879.JPG";
import ind7 from "../../../images/7.independence day/DSC_0889.JPG";
import ind8 from "../../../images/7.independence day/DSC_0901.JPG";
import ind9 from "../../../images/7.independence day/DSC_0904.JPG";
import ind10 from "../../../images/7.independence day/DSC_0906.JPG";
import ind11 from "../../../images/7.independence day/DSC_0909.JPG";
import ind12 from "../../../images/7.independence day/DSC_0942.JPG";
import ind13 from "../../../images/7.independence day/DSC_0973.JPG";
import ind14 from "../../../images/7.independence day/DSC_1004.JPG";
import ind15 from "../../../images/7.independence day/DSC_1016.JPG";
import race1 from "../../../images/8.cross country race/_DSC0259.JPG";
import race2 from "../../../images/8.cross country race/_DSC0264.JPG";
import race3 from "../../../images/8.cross country race/_DSC0288.JPG";
import race4 from "../../../images/8.cross country race/_DSC0292.JPG";
import race5 from "../../../images/8.cross country race/_DSC0331.JPG";
import race6 from "../../../images/8.cross country race/_DSC0412.JPG";
import race7 from "../../../images/8.cross country race/_DSC0433.JPG";
import race8 from "../../../images/8.cross country race/_DSC0442.JPG";
import race9 from "../../../images/8.cross country race/_DSC0447.JPG";
import race10 from "../../../images/8.cross country race/_DSC0629.JPG";
import rak1 from "../../../images/9.raksha bandan/_DSC0223.JPG";
import rak2 from "../../../images/9.raksha bandan/_DSC0227.JPG";
import rak3 from "../../../images/9.raksha bandan/_DSC0228.JPG";
import rak4 from "../../../images/9.raksha bandan/_DSC0241.JPG";
import rak5 from "../../../images/9.raksha bandan/_DSC0249.JPG";
import rak6 from "../../../images/9.raksha bandan/_DSC0259.JPG";
import rak7 from "../../../images/9.raksha bandan/_DSC0263.JPG";
import rak8 from "../../../images/9.raksha bandan/_DSC0270.JPG";
import rak9 from "../../../images/9.raksha bandan/_DSC0273.JPG";
import rak10 from "../../../images/9.raksha bandan/_DSC0275.JPG";
import dahi1 from "../../../images/10.dahi handi competition/_DSC0798.JPG";
import dahi2 from "../../../images/10.dahi handi competition/_DSC0827.JPG";
import dahi3 from "../../../images/10.dahi handi competition/_DSC0830.JPG";
import dahi4 from "../../../images/10.dahi handi competition/_DSC0849.JPG";
import dahi5 from "../../../images/10.dahi handi competition/_DSC0885.JPG";
import dahi6 from "../../../images/10.dahi handi competition/_DSC0890.JPG";
import dahi7 from "../../../images/10.dahi handi competition/_DSC0905.JPG";
import dahi8 from "../../../images/10.dahi handi competition/_DSC0931.JPG";
import teach1 from "../../../images/11. teachers day/_DSC0332.JPG";
import teach2 from "../../../images/11. teachers day/_DSC0336.JPG";
import teach3 from "../../../images/11. teachers day/_DSC0339.JPG";
import teach4 from "../../../images/11. teachers day/_DSC0347.JPG";
import teach5 from "../../../images/11. teachers day/_DSC0354.JPG";
import teach6 from "../../../images/11. teachers day/_DSC0368.JPG";
import teach7 from "../../../images/11. teachers day/_DSC0381.JPG";
import teach8 from "../../../images/11. teachers day/_DSC0395.JPG";
import teach9 from "../../../images/11. teachers day/_DSC0396.JPG";
import teach10 from "../../../images/11. teachers day/_DSC0403.JPG";
import teach11 from "../../../images/11. teachers day/_DSC0421.JPG";
import teach12 from "../../../images/11. teachers day/_DSC0441.JPG";
import hindhi1 from "../../../images/12. Hindi Diwas/_DSC0148.JPG";
import hindhi2 from "../../../images/12. Hindi Diwas/_DSC0229.JPG";
import hindhi3 from "../../../images/12. Hindi Diwas/_DSC0240.JPG";
import hindhi4 from "../../../images/12. Hindi Diwas/_DSC0252.JPG";
import hindhi5 from "../../../images/12. Hindi Diwas/_DSC0335.JPG";
import hindhi6 from "../../../images/12. Hindi Diwas/_DSC0346.JPG";
import hindhi7 from "../../../images/12. Hindi Diwas/_DSC0401.JPG";
import hindhi8 from "../../../images/12. Hindi Diwas/_DSC0450.JPG";
import hindhi9 from "../../../images/12. Hindi Diwas/_DSC0459.JPG";
import hindhi10 from "../../../images/12. Hindi Diwas/_DSC0478.JPG";
import hindhi11 from "../../../images/12. Hindi Diwas/_DSC0534.JPG";
import hindhi12 from "../../../images/12. Hindi Diwas/_DSC0579.JPG";
import raj1 from "../../../images/photo24/karnataka24/kar1.JPG"
import raj2 from "../../../images/photo24/karnataka24/kar2.JPG"
import raj3 from "../../../images/photo24/karnataka24/kar3.JPG"
import raj4 from "../../../images/photo24/karnataka24/kar4.JPG"
import raj5 from "../../../images/photo24/karnataka24/kar5.JPG"
import raj6 from "../../../images/photo24/karnataka24/kar6.JPG"
import raj7 from "../../../images/photo24/karnataka24/kar7.JPG"
import raj8 from "../../../images/photo24/karnataka24/kar8.JPG"
import children1 from "../../../images/photo24/childrenday24/c1.JPG";
import children2 from "../../../images/photo24/childrenday24/c2.JPG";
import children3 from "../../../images/photo24/childrenday24/c3.JPG";
import children4 from "../../../images/photo24/childrenday24/c4.JPG";
import children5 from "../../../images/photo24/childrenday24/c5.JPG";
import children6 from "../../../images/photo24/childrenday24/c6.JPG";
import children7 from "../../../images/photo24/childrenday24/c7.JPG";
import annual1 from "../../../images/photo24/annualday24/a1.JPG";
import annual2 from "../../../images/photo24/annualday24/a2.JPG";
import annual3 from "../../../images/photo24/annualday24/a3.JPG";
import annual4 from "../../../images/photo24/annualday24/a4.JPG";
import annual5 from "../../../images/photo24/annualday24/a5.JPG";
import annual6 from "../../../images/photo24/annualday24/a6.JPG";
import annual7 from "../../../images/photo24/annualday24/a7.JPG";
import annual8 from "../../../images/photo24/annualday24/a8.JPG";


const Gallery = ({ label, image }) => {
  const [gallery, setGallery] = useState([]);
  const [eventTypeId, setEventTypeId] = useState(0);
  const [eventTypeName, setEventTypeName] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [eventData, setEventData] = useState([]);

  //photos of WORLD ENVIRONMENT DAY
  const group = [
    { id: 1, label: "Card 1", image: world1 },
    { id: 2, label: "Card 2", image: world2 },
    { id: 3, label: "Card 3", image: world3 },
    { id: 4, label: "Card 4", image: world4 },
    { id: 5, label: "Card 5", image: world5 },
    { id: 6, label: "Card 6", image: world6 },
    { id: 7, label: "Card 7", image: world7 },
    { id: 8, label: "Card 8", image: world8 },
    { id: 9, label: "Card 9", image: world9 },
    { id: 10, label: "Card 10", image: world10 },
  ];

  //photos of saraswati pooja & Orientation program
  const yogaGroup = [
    { id: 1, label: "Card 1", image: sara1 },
    { id: 2, label: "Card 2", image: sara2 },
    { id: 3, label: "Card 3", image: sara3 },
    { id: 4, label: "Card 4", image: sara4 },
    { id: 5, label: "Card 5", image: sara5 },
    { id: 6, label: "Card 6", image: sara6 },
    { id: 7, label: "Card 7", image: sara7 },
    { id: 8, label: "Card 8", image: sara8 },
    { id: 9, label: "Card 9", image: sara9 },
    { id: 10, label: "Card 10", image: sara10 },
  ];

  //yoga day
  const swamjiBirthday = [
    { id: 1, label: "Card 1", image: yoga1 },
    { id: 2, label: "Card 2", image: yoga2 },
    { id: 3, label: "Card 3", image: yoga3 },
    { id: 4, label: "Card 4", image: yoga4 },
    { id: 5, label: "Card 5", image: yoga5 },
    { id: 6, label: "Card 6", image: yoga6 },
    { id: 7, label: "Card 7", image: yoga7 },
    { id: 8, label: "Card 8", image: yoga8 },
    { id: 9, label: "Card 9", image: yoga9 },
    { id: 10, label: "Card 10", image: yoga10 },
  ];

  // Fresher day
  const fresherday = [
    { id: 1, label: "Card 1", image: fresher1 },
    { id: 2, label: "Card 2", image: fresher2 },
    { id: 3, label: "Card 3", image: fresher3 },
    { id: 4, label: "Card 4", image: fresher4 },
    { id: 5, label: "Card 5", image: fresher5 },
    { id: 6, label: "Card 6", image: fresher6 },
    { id: 7, label: "Card 7", image: fresher7 },
    { id: 8, label: "Card 8", image: fresher8 },
    { id: 9, label: "Card 9", image: fresher9 },
    { id: 10, label: "Card 10", image: fresher10 },
  ];

  //ABVP PROGRAM

  const abvp = [
    { id: 1, label: "Card 1", image: abvp1 },
    { id: 2, label: "Card 2", image: abvp2 },
    { id: 3, label: "Card 3", image: abvp3 },
    { id: 4, label: "Card 4", image: abvp4 },
    { id: 5, label: "Card 5", image: abvp5 },
    { id: 6, label: "Card 6", image: abvp6 },
    { id: 7, label: "Card 7", image: abvp7 },
    { id: 8, label: "Card 8", image: abvp8 },
    { id: 9, label: "Card 9", image: abvp9 },
    { id: 10, label: "Card 10", image: abvp10 },

  ]

  // Sanganabasava Swamiji Birthday

  const birthday = [
    { id: 1, label: "Card 1", image: bday1 },
    { id: 2, label: "Card 2", image: bday2 },
    { id: 3, label: "Card 3", image: bday3 },
    { id: 4, label: "Card 4", image: bday4 },
    { id: 5, label: "Card 5", image: bday5 },
    { id: 6, label: "Card 6", image: bday6 },
    { id: 7, label: "Card 7", image: bday7 },
    { id: 8, label: "Card 8", image: bday8 },
    { id: 9, label: "Card 9", image: bday9 },
    { id: 10, label: "Card 10", image: bday10 },
  ]

  //independce day
  const iday = [
    { id: 1, label: "card1", image: ind1 },
    { id: 2, label: "card2", image: ind2 },
    { id: 3, label: "card3", image: ind3 },
    { id: 4, label: "card4", image: ind4 },
    { id: 5, label: "card5", image: ind5 },
    { id: 6, label: "card6", image: ind6 },
    { id: 7, label: "card7", image: ind7 },
    { id: 8, label: "card8", image: ind8 },
    { id: 9, label: "card9", image: ind9 },
    { id: 10, label: "card10", image: ind10 },
    { id: 11, label: "card11", image: ind11 },
    { id: 12, label: "card12", image: ind12 },
    { id: 13, label: "card13", image: ind13 },
    { id: 14, label: "card14", image: ind14 },
    { id: 15, label: "card15", image: ind15 },
  ]

  //cross country race

  const race = [
    { id: 1, label: "card1", image: race1 },
    { id: 2, label: "card2", image: race2 },
    { id: 3, label: "card3", image: race3 },
    { id: 4, label: "card4", image: race4 },
    { id: 5, label: "card5", image: race5 },
    { id: 6, label: "card6", image: race6 },
    { id: 7, label: "card7", image: race7 },
    { id: 8, label: "card8", image: race8 },
    { id: 9, label: "card9", image: race9 },
    { id: 10, label: "card10", image: race10 },
  ]

  //raksha bandan

  const raksha = [
    { id: 1, label: "card1", image: rak1 },
    { id: 2, label: "card2", image: rak2 },
    { id: 3, label: "card3", image: rak3 },
    { id: 4, label: "card4", image: rak4 },
    { id: 5, label: "card5", image: rak5 },
    { id: 6, label: "card6", image: rak6 },
    { id: 7, label: "card7", image: rak7 },
    { id: 8, label: "card8", image: rak8 },
    { id: 9, label: "card9", image: rak9 },
    { id: 10, label: "card10", image: rak10 },
  ]

  //dahi handi competition

  const dahi = [
    { id: 1, label: "card1", image: dahi1 },
    { id: 2, label: "card2", image: dahi2 },
    { id: 3, label: "card3", image: dahi3 },
    { id: 4, label: "card4", image: dahi4 },
    { id: 5, label: "card5", image: dahi5 },
    { id: 6, label: "card6", image: dahi6 },
    { id: 7, label: "card7", image: dahi7 },
    { id: 8, label: "card8", image: dahi8 },
  ]


  //teachers day

  const teachday = [
    { id: 1, label: "card1", image: teach1 },
    { id: 2, label: "card2", image: teach2 },
    { id: 3, label: "card3", image: teach3 },
    { id: 4, label: "card4", image: teach4 },
    { id: 5, label: "card5", image: teach5 },
    { id: 6, label: "card6", image: teach6 },
    { id: 7, label: "card7", image: teach7 },
    { id: 8, label: "card8", image: teach8 },
    { id: 9, label: "card9", image: teach9 },
    { id: 10, label: "card10", image: teach10 },
    { id: 11, label: "card11", image: teach11 },
    { id: 12, label: "card12", image: teach12 },
  ]


  //Hindi Diwas

  const HindiDiwas = [
    { id: 1, label: "card1", image: hindhi1 },
    { id: 2, label: "card2", image: hindhi2 },
    { id: 3, label: "card3", image: hindhi3 },
    { id: 4, label: "card4", image: hindhi4 },
    { id: 5, label: "card5", image: hindhi5 },
    { id: 6, label: "card6", image: hindhi6 },
    { id: 7, label: "card7", image: hindhi7 },
    { id: 8, label: "card8", image: hindhi8 },
    { id: 9, label: "card9", image: hindhi9 },
    { id: 10, label: "card10", image: hindhi10 },
    { id: 11, label: "card11", image: hindhi11 },
    { id: 12, label: "card12", image: hindhi12 },
  ]


  const rajotsava = [
    { id: 1, label: "Card 1", image: raj1 },
    { id: 2, label: "Card 2", image: raj2 },
    { id: 3, label: "Card 3", image: raj3 },
    { id: 4, label: "Card 4", image: raj4 },
    { id: 5, label: "Card 5", image: raj5 },
    { id: 6, label: "Card 6", image: raj6 },
    { id: 7, label: "Card 7", image: raj7 },
    { id: 8, label: "Card 8", image: raj8 },

  ];

  const childrensDay = [
    { id: 1, label: "Card 1", image: children1 },
    { id: 2, label: "Card 2", image: children2 },
    { id: 3, label: "Card 3", image: children3 },
    { id: 4, label: "Card 4", image: children4 },
    { id: 5, label: "Card 5", image: children5 },
    { id: 6, label: "Card 6", image: children6 },
    { id: 7, label: "Card 7", image: children7 },
  ];


  const annualDay = [
    { id: 1, label: "Card 1", image: annual1 },
    { id: 2, label: "Card 2", image: annual2 },
    { id: 3, label: "Card 3", image: annual3 },
    { id: 4, label: "Card 4", image: annual4 },
    { id: 5, label: "Card 5", image: annual5 },
    { id: 6, label: "Card 6", image: annual6 },
    { id: 7, label: "Card 7", image: annual7 },
    { id: 8, label: "Card 8", image: annual8 },
  ];




  const user = JSON.parse(sessionStorage.getItem("user"));

  const headers = {
    "Content-type": "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbjEiLCJpYXQiOjE2NDgwMzE5OTUsImV4cCI6MTY2MzU4Mzk5NX0.h3uex4gcdMAA8cOMN_mqFbxlYhZRtFkQMLoke7y-8dXL0-oBy41qmWhkIJ-O0bNlaLcdHYxKygm3GHdd84bMVw",
  };

  const ImageUrl = `https://executivetracking.cloudjiffy.net/SRPUSchoolWeb/file/downloadFile/?filePath=`;

  const baseUrl = "https://executivetracking.cloudjiffy.net/SRPUSchoolWeb";

  const FetchData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/gallery/v1/getAllGalleryByPagination/{pageNumber}/{pageSize}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        setGallery(data.content);
        console.log("gallery===", data.content);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  const GalleryData = async (eventTypeId) => {
    console.log("evenId", eventTypeId);
    await axios({
      method: "get",
      url: `${baseUrl}/gallery/v1/getGallerysByPaginationEventType/{pageNumber}/{pageSize}/{eventTypeId}?eventTypeId=${eventTypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data.content;
        setGallery(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const EventData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/eventtype/v1/getAllEventType`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        setEventData(data);
        console.log("eventData==", data);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchData();
    EventData();
  }, [eventTypeId]);

  return (
    <div>
      <NavBar />
      {/* <!------- End of Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Media</li>
          </ol>
          <h2>Gallery</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      <section id="portfolio" class="portfolio">
        <div class="container">
          <header class="section-header">
            <Card className="text-center">
              <Card.Header>
                <h4>Gallery</h4>
              </Card.Header>
            </Card>
          </header>

          <div>
            <h2 className="text-center">World Environment Day</h2>

            {/* First row of images */}
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world3} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world4} alt="Image 4" />
                </Card>
              </Col>
            </Row>

            {/* Second row of images */}
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world5} alt="Image 5" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world6} alt="Image 6" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world7} alt="Image 7" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world8} alt="Image 8" />
                </Card>
              </Col>
            </Row>

            {/* Third row for remaining images */}
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world9} alt="Image 9" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={world10} alt="Image 10" />
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Saraswati Pooja & Orientation Program
            </h2>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={sara1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={sara2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={sara3} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={sara4} alt="Image 4" />
                </Card>
              </Col>
            </Row>

            <div className="card-container">
              <Row className="justify-content-center">
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara5} alt="Image 5" />
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara6} alt="Image 6" />
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara7} alt="Image 7" />
                  </Card>
                </Col>
              </Row>
            </div>

            {/* New Row for additional three photos */}
            <div className="card-container">
              <Row className="justify-content-center">
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara8} alt="Image 8" />
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara9} alt="Image 9" />
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={sara10} alt="Image 10" />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <h2 className="text-center"> Yoga Day</h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {swamjiBirthday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center"> Freshers Day </h2>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher3} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher4} alt="Image 4" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher5} alt="Image 5" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher6} alt="Image 6" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher7} alt="Image 7" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher8} alt="Image 8" />
                </Card>
              </Col>
              {/* <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher9} alt="Image 9" />
                </Card>
              </Col> */}
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fresher10} alt="Image 10" />
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              ABVP Program
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {abvp.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Sanganabasava Swamiji Birthday
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {birthday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Independence Day
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {iday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Cross Country Race
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {race.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Raksha Bandan
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {raksha.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Dahi Handi Competition
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {dahi.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Teachers Day Celebration
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {teachday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Hindi Diwas
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {HindiDiwas.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">Karnataka Rajyotsava</h2>
            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {rajotsava.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">Children's Day Celebration</h2>
            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {childrensDay.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">Annual Day Celebration</h2>
            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {annualDay.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>







          {/* <div class="row">
            <div class="col-lg-12 d-flex justify-content-center">
              <div className="event-card m-2">
                <Button
                  className="m-2"
                  class=" rounded-pill m-1 button-event"
                  onClick={(e) => FetchData()}
                >
                  {"All"}
                </Button>
                {eventData.map((data, index) => (
                  <Button
                    className="m-2"
                    class="btn btn-primary rounded-pill m-1"
                    key={index}
                    onClick={(e) => GalleryData(data.eventTypeId)}
                  >
                    {data.eventTypeName}
                  </Button>
                ))}
              </div>
            </div>
          </div> */}

          {/* <div class="row row-cols-1 row-cols-md-3 g-4">
            {gallery.map((item, index) => {
              return (
                <div class="col">
                  <div class="card">
                    <img
                      src={ImageUrl + encodeURIComponent(item.galleryPicPath)}
                      class="img-fluid"
                      alt="Gallery"
                    />
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default Gallery;
