export const data = {
    "Sheet1":[
     
     {
      "SL No": 2,
      "Phone No.": 9900700656,
      "SATS NUMBER": 130273765,
      "Name of the Student": "Akash Pundalik Kantekoor",
      "Language 1": 97,
      "Language 2": 59,
      "Physics": 73,
      "Chemistry": 77,
      "Mathematics": 94,
      "Biology\/\nComputer Science": 97,
      "Total": 497,
      "Per": 82.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 3,
      "Phone No.": 9480385606,
      "SATS NUMBER": 256478506,
      "Name of the Student": "Anushree Tulajannavar",
      "Language 1": 82,
      "Language 2": 97,
      "Physics": 88,
      "Chemistry": 80,
      "Mathematics": 94,
      "Biology\/\nComputer Science": 95,
      "Total": 536,
      "Per": 89.33333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 4,
      "Phone No.": 9036905242,
      "SATS NUMBER": 256771412,
      "Name of the Student": "Ashish Shindhe",
      "Language 1": 80,
      "Language 2": 81,
      "Physics": 73,
      "Chemistry": 83,
      "Mathematics": 95,
      "Biology\/\nComputer Science": 88,
      "Total": 500,
      "Per": 83.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 5,
      "Phone No.": 9945424826,
      "SATS NUMBER": 17314319,
      "Name of the Student": "Bhimanagouda Bagali",
      "Language 1": 90,
      "Language 2": 60,
      "Physics": 45,
      "Chemistry": 64,
      "Mathematics": 82,
      "Biology\/\nComputer Science": 61,
      "Total": 402,
      "Per": 67,
      "Result": "First Class"
     },
     {
      "SL No": 6,
      "Phone No.": 9845558271,
      "SATS NUMBER": 14702141,
      "Name of the Student": "Channamma Sahebagoud Patil",
      "Language 1": 99,
      "Language 2": 75,
      "Physics": 92,
      "Chemistry": 88,
      "Mathematics": 98,
      "Biology\/\nComputer Science": 93,
      "Total": 545,
      "Per": 90.83333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 7,
      "Phone No.": 9535769456,
      "SATS NUMBER": 256470507,
      "Name of the Student": "Gopikrishna",
      "Language 1": 97,
      "Language 2": 84,
      "Physics": 70,
      "Chemistry": 93,
      "Mathematics": 84,
      "Biology\/\nComputer Science": 99,
      "Total": 527,
      "Per": 87.83333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 8,
      "Phone No.": 9101405011,
      "SATS NUMBER": 116295079,
      "Name of the Student": "Gurukiran",
      "Language 1": 75,
      "Language 2": 83,
      "Physics": 67,
      "Chemistry": 87,
      "Mathematics": 89,
      "Biology\/\nComputer Science": 97,
      "Total": 498,
      "Per": 83,
      "Result": "First Class"
     },
     {
      "SL No": 9,
      "Phone No.": 8660990102,
      "SATS NUMBER": 90451131,
      "Name of the Student": "Jagdish Shivanand Shilin",
      "Language 1": 96,
      "Language 2": 99,
      "Physics": 94,
      "Chemistry": 97,
      "Mathematics": 99,
      "Biology\/\nComputer Science": 99,
      "Total": 584,
      "Per": 97.33333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 10,
      "Phone No.": 9902658171,
      "SATS NUMBER": 256771816,
      "Name of the Student": "Kamalakshi Hajeri",
      "Language 1": 91,
      "Language 2": 94,
      "Physics": 86,
      "Chemistry": 96,
      "Mathematics": 87,
      "Biology\/\nComputer Science": 91,
      "Total": 545,
      "Per": 90.83333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 11,
      "Phone No.": 9448646330,
      "SATS NUMBER": 20198025,
      "Name of the Student": "Kishor Kambar",
      "Language 1": 67,
      "Language 2": 73,
      "Physics": 59,
      "Chemistry": 74,
      "Mathematics": 77,
      "Biology\/\nComputer Science": 84,
      "Total": 434,
      "Per": 72.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 12,
      "Phone No.": 7499716933,
      "SATS NUMBER": 156517256,
      "Name of the Student": "Kumar Balagond Teli",
      "Language 1": 98,
      "Language 2": 71,
      "Physics": 86,
      "Chemistry": 86,
      "Mathematics": 99,
      "Biology\/\nComputer Science": 99,
      "Total": 539,
      "Per": 89.83333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 13,
      "Phone No.": 9901815331,
      "SATS NUMBER": 14682616,
      "Name of the Student": "Lakshmi Somashekhar Kanamuchanal",
      "Language 1": 97,
      "Language 2": 82,
      "Physics": 82,
      "Chemistry": 69,
      "Mathematics": 73,
      "Biology\/\nComputer Science": 94,
      "Total": 497,
      "Per": 82.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 14,
      "Phone No.": 8105112699,
      "SATS NUMBER": 35286642,
      "Name of the Student": "Laxmi Shankaragouda Hachadad",
      "Language 1": 97,
      "Language 2": 76,
      "Physics": 87,
      "Chemistry": 83,
      "Mathematics": 92,
      "Biology\/\nComputer Science": 94,
      "Total": 529,
      "Per": 88.16666666666667,
      "Result": "Distinction"
     },
     {
      "SL No": 15,
      "Phone No.": 9611179028,
      "SATS NUMBER": 34742013,
      "Name of the Student": "Mahantesh",
      "Language 1": 80,
      "Language 2": 76,
      "Physics": 83,
      "Chemistry": 81,
      "Mathematics": 89,
      "Biology\/\nComputer Science": 88,
      "Total": 497,
      "Per": 82.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 16,
      "Phone No.": 9480120192,
      "SATS NUMBER": 31094459,
      "Name of the Student": "Mamata Kanaram Choudhari",
      "Language 1": 90,
      "Language 2": 98,
      "Physics": 78,
      "Chemistry": 91,
      "Mathematics": 85,
      "Biology\/\nComputer Science": 95,
      "Total": 537,
      "Per": 89.5,
      "Result": "Distinction"
     },
     {
      "SL No": 17,
      "Phone No.": 9845676828,
      "SATS NUMBER": 34239475,
      "Name of the Student": "Manisha",
      "Language 1": 90,
      "Language 2": 87,
      "Physics": 76,
      "Chemistry": 86,
      "Mathematics": 76,
      "Biology\/\nComputer Science": 96,
      "Total": 511,
      "Per": 85.16666666666667,
      "Result": "Distinction"
     },
     {
      "SL No": 18,
      "Phone No.": 9880882130,
      "SATS NUMBER": 20341129,
      "Name of the Student": "Mayuri Dayanand Pattar",
      "Language 1": 86,
      "Language 2": 57,
      "Physics": 83,
      "Chemistry": 83,
      "Mathematics": 96,
      "Biology\/\nComputer Science": 95,
      "Total": 500,
      "Per": 83.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 19,
      "Phone No.": 9900764070,
      "SATS NUMBER": 37408969,
      "Name of the Student": "Navya Biradar",
      "Language 1": 77,
      "Language 2": 69,
      "Physics": 69,
      "Chemistry": 56,
      "Mathematics": 78,
      "Biology\/\nComputer Science": 94,
      "Total": 443,
      "Per": 73.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 20,
      "Phone No.": 9611501775,
      "SATS NUMBER": 87025298,
      "Name of the Student": "Nisha Hawshetty",
      "Language 1": 82,
      "Language 2": 84,
      "Physics": 63,
      "Chemistry": 75,
      "Mathematics": 61,
      "Biology\/\nComputer Science": 77,
      "Total": 442,
      "Per": 73.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 21,
      "Phone No.": 9740440087,
      "SATS NUMBER": 256772391,
      "Name of the Student": "Nitin Gudimani",
      "Language 1": 76,
      "Language 2": 94,
      "Physics": 61,
      "Chemistry": 83,
      "Mathematics": 81,
      "Biology\/\nComputer Science": 88,
      "Total": 483,
      "Per": 80.5,
      "Result": "First Class"
     },
     {
      "SL No": 22,
      "Phone No.": 8660852354,
      "SATS NUMBER": 257746033,
      "Name of the Student": "Pavankumar Ravikant Koulagi",
      "Language 1": 77,
      "Language 2": 80,
      "Physics": 72,
      "Chemistry": 95,
      "Mathematics": 96,
      "Biology\/\nComputer Science": 86,
      "Total": 506,
      "Per": 84.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 23,
      "Phone No.": 9880140831,
      "SATS NUMBER": 128915229,
      "Name of the Student": "Pradeep",
      "Language 1": 94,
      "Language 2": 75,
      "Physics": 72,
      "Chemistry": 77,
      "Mathematics": 72,
      "Biology\/\nComputer Science": 89,
      "Total": 479,
      "Per": 79.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 24,
      "Phone No.": 9880246079,
      "SATS NUMBER": 257953459,
      "Name of the Student": "Pragati Patil",
      "Language 1": 87,
      "Language 2": 86,
      "Physics": 72,
      "Chemistry": 66,
      "Mathematics": 59,
      "Biology\/\nComputer Science": 77,
      "Total": 447,
      "Per": 74.5,
      "Result": "First Class"
     },
     {
      "SL No": 25,
      "Phone No.": 8088586233,
      "SATS NUMBER": 256777609,
      "Name of the Student": "Prajwal Roogi",
      "Language 1": 92,
      "Language 2": 84,
      "Physics": 70,
      "Chemistry": 90,
      "Mathematics": 96,
      "Biology\/\nComputer Science": 99,
      "Total": 531,
      "Per": 88.5,
      "Result": "Distinction"
     },
     {
      "SL No": 26,
      "Phone No.": 9590374542,
      "SATS NUMBER": 257615397,
      "Name of the Student": "Praveenkumar Tikamaram Devasi",
      "Language 1": 75,
      "Language 2": 91,
      "Physics": 57,
      "Chemistry": 89,
      "Mathematics": 87,
      "Biology\/\nComputer Science": 73,
      "Total": 472,
      "Per": 78.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 27,
      "Phone No.": 9448896999,
      "SATS NUMBER": 31192752,
      "Name of the Student": "Preetam Balagavi",
      "Language 1": 55,
      "Language 2": 86,
      "Physics": 63,
      "Chemistry": 75,
      "Mathematics": 73,
      "Biology\/\nComputer Science": 78,
      "Total": 430,
      "Per": 71.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 28,
      "Phone No.": 8105472206,
      "SATS NUMBER": 28116653,
      "Name of the Student": "Preeti",
      "Language 1": 98,
      "Language 2": 87,
      "Physics": 89,
      "Chemistry": 89,
      "Mathematics": 94,
      "Biology\/\nComputer Science": 91,
      "Total": 548,
      "Per": 91.33333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 29,
      "Phone No.": 9972693207,
      "SATS NUMBER": 46154276,
      "Name of the Student": "Pruthvi Basutkar",
      "Language 1": 69,
      "Language 2": 94,
      "Physics": 64,
      "Chemistry": 81,
      "Mathematics": 78,
      "Biology\/\nComputer Science": 90,
      "Total": 476,
      "Per": 79.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 30,
      "Phone No.": 9008146866,
      "SATS NUMBER": 34743467,
      "Name of the Student": "Raghavendra",
      "Language 1": 80,
      "Language 2": 77,
      "Physics": 58,
      "Chemistry": 60,
      "Mathematics": 66,
      "Biology\/\nComputer Science": 81,
      "Total": 422,
      "Per": 70.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 31,
      "Phone No.": 9964700672,
      "SATS NUMBER": 131956425,
      "Name of the Student": "Rakhi Talekari",
      "Language 1": 73,
      "Language 2": 91,
      "Physics": 83,
      "Chemistry": 79,
      "Mathematics": 80,
      "Biology\/\nComputer Science": 89,
      "Total": 495,
      "Per": 82.5,
      "Result": "First Class"
     },
     {
      "SL No": 32,
      "Phone No.": 9901858528,
      "SATS NUMBER": 17921238,
      "Name of the Student": "Rakshita Hyavagal",
      "Language 1": 98,
      "Language 2": 70,
      "Physics": 62,
      "Chemistry": 77,
      "Mathematics": 83,
      "Biology\/\nComputer Science": 85,
      "Total": 475,
      "Per": 79.16666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 33,
      "Phone No.": 9845079846,
      "SATS NUMBER": 27775483,
      "Name of the Student": "Ravikant Biradar",
      "Language 1": 82,
      "Language 2": 68,
      "Physics": 61,
      "Chemistry": 91,
      "Mathematics": 72,
      "Biology\/\nComputer Science": 67,
      "Total": 441,
      "Per": 73.5,
      "Result": "First Class"
     },
     {
      "SL No": 34,
      "Phone No.": 7204520774,
      "SATS NUMBER": 111159449,
      "Name of the Student": "Sahana Sarawad",
      "Language 1": 90,
      "Language 2": 82,
      "Physics": 70,
      "Chemistry": 76,
      "Mathematics": 88,
      "Biology\/\nComputer Science": 83,
      "Total": 489,
      "Per": 81.5,
      "Result": "First Class"
     },
     {
      "SL No": 35,
      "Phone No.": 9880780955,
      "SATS NUMBER": 18671765,
      "Name of the Student": "Sakshi Bapugoud Patil",
      "Language 1": 65,
      "Language 2": 74,
      "Physics": 72,
      "Chemistry": 75,
      "Mathematics": 96,
      "Biology\/\nComputer Science": 81,
      "Total": 463,
      "Per": 77.16666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 36,
      "Phone No.": 9886211300,
      "SATS NUMBER": 58131253,
      "Name of the Student": "Sakshi Ramachandragouda Topannavar",
      "Language 1": 70,
      "Language 2": 59,
      "Physics": 57,
      "Chemistry": 78,
      "Mathematics": 61,
      "Biology\/\nComputer Science": 62,
      "Total": 387,
      "Per": 64.5,
      "Result": "First Class"
     },
     {
      "SL No": 37,
      "Phone No.": 9972345694,
      "SATS NUMBER": 21054476,
      "Name of the Student": "Sanjana",
      "Language 1": 94,
      "Language 2": 73,
      "Physics": 81,
      "Chemistry": 86,
      "Mathematics": 86,
      "Biology\/\nComputer Science": 83,
      "Total": 503,
      "Per": 83.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 38,
      "Phone No.": 9945424826,
      "SATS NUMBER": 48106069,
      "Name of the Student": "Sanketagoud Biradar",
      "Language 1": 69,
      "Language 2": 47,
      "Physics": 56,
      "Chemistry": 62,
      "Mathematics": 51,
      "Biology\/\nComputer Science": 77,
      "Total": 362,
      "Per": 60.333333333333336,
      "Result": "First Class"
     },
     {
      "SL No": 39,
      "Phone No.": 9900776230,
      "SATS NUMBER": 256779174,
      "Name of the Student": "Shashank Manohar Vishwakarma",
      "Language 1": 93,
      "Language 2": 88,
      "Physics": 81,
      "Chemistry": 71,
      "Mathematics": 67,
      "Biology\/\nComputer Science": 89,
      "Total": 489,
      "Per": 81.5,
      "Result": "First Class"
     },
     {
      "SL No": 40,
      "Phone No.": 9611835270,
      "SATS NUMBER": 256781716,
      "Name of the Student": "Sheetal Talkeri",
      "Language 1": 69,
      "Language 2": 71,
      "Physics": 75,
      "Chemistry": 95,
      "Mathematics": 74,
      "Biology\/\nComputer Science": 83,
      "Total": 467,
      "Per": 77.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 41,
      "Phone No.": 8748951449,
      "SATS NUMBER": 36259656,
      "Name of the Student": "Shivasharan Aralagundagi",
      "Language 1": 92,
      "Language 2": 48,
      "Physics": 46,
      "Chemistry": 63,
      "Mathematics": 62,
      "Biology\/\nComputer Science": 76,
      "Total": 387,
      "Per": 64.5,
      "Result": "First Class"
     },
     {
      "SL No": 42,
      "Phone No.": 990688131,
      "SATS NUMBER": 34744648,
      "Name of the Student": "Shobha",
      "Language 1": 90,
      "Language 2": 77,
      "Physics": 77,
      "Chemistry": 88,
      "Mathematics": 80,
      "Biology\/\nComputer Science": 89,
      "Total": 501,
      "Per": 83.5,
      "Result": "First Class"
     },
     {
      "SL No": 43,
      "Phone No.": 9449105678,
      "SATS NUMBER": 37825854,
      "Name of the Student": "Shraddha Bilagi",
      "Language 1": 96,
      "Language 2": 85,
      "Physics": 72,
      "Chemistry": 69,
      "Mathematics": 80,
      "Biology\/\nComputer Science": 77,
      "Total": 479,
      "Per": 79.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 44,
      "Phone No.": 9880117351,
      "SATS NUMBER": 105534214,
      "Name of the Student": "Shreedevi N",
      "Language 1": 87,
      "Language 2": 78,
      "Physics": 77,
      "Chemistry": 66,
      "Mathematics": 67,
      "Biology\/\nComputer Science": 94,
      "Total": 469,
      "Per": 78.16666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 45,
      "Phone No.": 9845684763,
      "SATS NUMBER": 258110713,
      "Name of the Student": "Shreelakshmi Balachandra Biradar",
      "Language 1": 94,
      "Language 2": 82,
      "Physics": 81,
      "Chemistry": 82,
      "Mathematics": 92,
      "Biology\/\nComputer Science": 82,
      "Total": 513,
      "Per": 85.5,
      "Result": "Distinction"
     },
     {
      "SL No": 46,
      "Phone No.": 8548008303,
      "SATS NUMBER": 35286651,
      "Name of the Student": "Shreeshail Paramanand Hachadad",
      "Language 1": 91,
      "Language 2": 55,
      "Physics": 56,
      "Chemistry": 61,
      "Mathematics": 85,
      "Biology\/\nComputer Science": 82,
      "Total": 430,
      "Per": 71.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 47,
      "Phone No.": 7019799278,
      "SATS NUMBER": 36833801,
      "Name of the Student": "Shridhar Hanamanta Golasar",
      "Language 1": 97,
      "Language 2": 67,
      "Physics": 62,
      "Chemistry": 59,
      "Mathematics": 71,
      "Biology\/\nComputer Science": 89,
      "Total": 445,
      "Per": 74.16666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 48,
      "Phone No.": 9900279176,
      "SATS NUMBER": 87340015,
      "Name of the Student": "Swapna",
      "Language 1": 76,
      "Language 2": 96,
      "Physics": 71,
      "Chemistry": 69,
      "Mathematics": 60,
      "Biology\/\nComputer Science": 86,
      "Total": 458,
      "Per": 76.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 49,
      "Phone No.": 9972775065,
      "SATS NUMBER": 21744347,
      "Name of the Student": "Udayashankar Nandaragi",
      "Language 1": 95,
      "Language 2": 77,
      "Physics": 69,
      "Chemistry": 84,
      "Mathematics": 84,
      "Biology\/\nComputer Science": 95,
      "Total": 504,
      "Per": 84,
      "Result": "First Class"
     },
     {
      "SL No": 50,
      "Phone No.": 9900631377,
      "SATS NUMBER": 256782627,
      "Name of the Student": "Ujawala Kare",
      "Language 1": 95,
      "Language 2": 83,
      "Physics": 69,
      "Chemistry": 68,
      "Mathematics": 69,
      "Biology\/\nComputer Science": 94,
      "Total": 478,
      "Per": 79.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 51,
      "Phone No.": 8792429301,
      "SATS NUMBER": 36082171,
      "Name of the Student": "Umesh Jeratagi",
      "Language 1": 95,
      "Language 2": 58,
      "Physics": 68,
      "Chemistry": 59,
      "Mathematics": 64,
      "Biology\/\nComputer Science": 92,
      "Total": 436,
      "Per": 72.66666666666667,
      "Result": "First Class"
     },
     {
      "SL No": 52,
      "Phone No.": 9880194021,
      "SATS NUMBER": 36190111,
      "Name of the Student": "Vidya Sahadev Nikkam",
      "Language 1": 75,
      "Language 2": 98,
      "Physics": 74,
      "Chemistry": 86,
      "Mathematics": 91,
      "Biology\/\nComputer Science": 91,
      "Total": 515,
      "Per": 85.83333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 53,
      "Phone No.": 9741105363,
      "SATS NUMBER": 257650385,
      "Name of the Student": "Vijayakumar Bagewadi",
      "Language 1": 82,
      "Language 2": 96,
      "Physics": 59,
      "Chemistry": 89,
      "Mathematics": 91,
      "Biology\/\nComputer Science": 74,
      "Total": 491,
      "Per": 81.83333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 54,
      "Phone No.": 9901451705,
      "SATS NUMBER": 256783257,
      "Name of the Student": "Vijayakumar Kanamadi",
      "Language 1": 99,
      "Language 2": 87,
      "Physics": 85,
      "Chemistry": 98,
      "Mathematics": 97,
      "Biology\/\nComputer Science": 96,
      "Total": 562,
      "Per": 93.66666666666667,
      "Result": "Distinction"
     },
     {
      "SL No": 55,
      "Phone No.": 8792020453,
      "SATS NUMBER": 2790876,
      "Name of the Student": "Vilas Jadhav",
      "Language 1": 85,
      "Language 2": 99,
      "Physics": 85,
      "Chemistry": 96,
      "Mathematics": 99,
      "Biology\/\nComputer Science": 90,
      "Total": 554,
      "Per": 92.33333333333333,
      "Result": "Distinction"
     },
     {
      "SL No": 56,
      "Phone No.": 9482446573,
      "SATS NUMBER": 33618073,
      "Name of the Student": "Vishal Balaganur",
      "Language 1": 94,
      "Language 2": 85,
      "Physics": 64,
      "Chemistry": 78,
      "Mathematics": 53,
      "Biology\/\nComputer Science": 72,
      "Total": 446,
      "Per": 74.33333333333333,
      "Result": "First Class"
     },
     {
      "SL No": 57,
      "Phone No.": 9448575062,
      "SATS NUMBER": 33664437,
      "Name of the Student": "Vivek R Otageri",
      "Language 1": 87,
      "Language 2": 96,
      "Physics": 87,
      "Chemistry": 86,
      "Mathematics": 97,
      "Biology\/\nComputer Science": 91,
      "Total": 544,
      "Per": 90.66666666666667,
      "Result": "Distinction"
     },
     
    ]
    }