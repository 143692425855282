import React, { useState } from "react";
import { Container, Form, Button, Card, Table, Row, Col } from "react-bootstrap";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import { data } from "../Results/data";
import logo from "../../../images/logo_header.png"



const ViewResult = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");

  const handleSearch = () => {
    const student = data.Sheet1.find(
      (student) => student["Phone No."] === parseInt(phoneNumber)
    );

    if (student) {
      setResult(student);
      setError("");
    } else {
      setError("No student found with this phone number");
      setResult(null);
    }
  };

  const handleDownloadPDF = () => {
    if (!result) return;
  
    const doc = new jsPDF();
    
    const img = new Image();
    img.src = logo;
    
    img.onload = function () {
      doc.addImage(img, "PNG", 15, 10, 20, 20);
      doc.setFont("helvetica", "bold");
      doc.text("Sanganabasava Residential PU College, Kavalagi ", 40, 20);
  
      doc.setFont("helvetica", "normal");
      doc.text(`Student Name: ${result["Name of the Student"]}`, 15, 40);
      doc.text(`SATS NUMBER: ${result["SATS NUMBER"]}`, 15, 50);
  
      const tableData = [
        ["Language 1", result["Language 1"]],
        ["Language 2", result["Language 2"]],
        ["Physics", result["Physics"]],
        ["Chemistry", result["Chemistry"]],
        ["Mathematics", result["Mathematics"]],
        ["Biology/Computer Science", result["Biology/\nComputer Science"]],
        ["Total Marks", result["Total"]],
        ["Percentage", `${result["Per"].toFixed(2)}%`],
        ["Result", result["Result"]],
      ];
  
      autoTable(doc, {
        startY: 60,
        head: [["Subject", "Marks"]],
        body: tableData,
        theme: "striped",
      });
  
      doc.save(`Exam_Result_${result["Name of the Student"]}.pdf`);
    };
  };
  

  return (
    <>
      <NavBar />

      <Container style={{ 
        marginTop: "150px", 
        maxWidth: "800px",
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
      }}>
        <Card className="border-0 shadow-sm mb-4" style={{ backgroundColor: "#f8f9fa" }}>
          <Card.Body style={{ padding: "2rem" }}>
            <h4 className="text-center mb-4" style={{ 
              color: "#495057",
              fontWeight: "600",
              letterSpacing: "0.5px"
            }}>
             Check Your Exam Results 2025
            </h4>
            
            <Row className="align-items-center">
              <Col md={8} sm={12} className="mb-3 mb-md-0">
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label style={{
                    fontWeight: "500",
                    color: "#6c757d"
                  }}>
                    Enter Registered Phone Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. 98XXXXXXXX"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/, ""))}
                    style={{
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      borderColor: "#ced4da",
                      backgroundColor: "#ffffff"
                    }}
                  />
                  <Form.Text className="text-muted" style={{ fontSize: "0.85rem" }}>
                    Enter the phone number used during registration
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={4} sm={12}>
                <Button 
                  variant="primary" 
                  onClick={handleSearch}
                  style={{
                    width: "100%",
                    padding: "0.75rem",
                    borderRadius: "0.375rem",
                    fontWeight: "500",
                    backgroundColor: "#5a8dee",
                    borderColor: "#5a8dee",
                    boxShadow: "none",
                    transition: "all 0.2s ease"
                  }}
                  className="hover-effect"
                >
                  Get Results
                </Button>
              </Col>
            </Row>

            {error && (
              <div className="mt-3 alert alert-warning" role="alert" style={{
                backgroundColor: "rgba(255,193,7,0.1)",
                borderColor: "rgba(255,193,7,0.3)",
                color: "#856404"
              }}>
                <i className="fas fa-exclamation-circle me-2"></i>
                {error}
              </div>
            )}
          </Card.Body>
        </Card>

        {result && (
          <Card className="mt-4 border-0 shadow-sm" style={{ backgroundColor: "#f8f9fa" }}>
            <Card.Body style={{ padding: "2rem" }}>
              <h5 className="text-center mb-4" style={{
                color: "#495057",
                fontWeight: "600",
                letterSpacing: "0.5px"
              }}>
                I PUC Annual Exam Result - {result["Name of the Student"]}
              </h5>

              <Table striped bordered hover className="mb-4" style={{
                borderColor: "#dee2e6",
                backgroundColor: "#ffffff"
              }}>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Language 1</strong></td>
                    <td>{result["Language 1"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Language 2</strong></td>
                    <td>{result["Language 2"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Physics</strong></td>
                    <td>{result["Physics"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Chemistry</strong></td>
                    <td>{result["Chemistry"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Mathematics</strong></td>
                    <td>{result["Mathematics"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Biology/Computer Science</strong></td>
                    <td>{result["Biology/\nComputer Science"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Total Marks</strong></td>
                    <td>{result["Total"]}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Percentage</strong></td>
                    <td>{result["Per"].toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f8f9fa" }}><strong>Result</strong></td>
                    <td style={{ 
                      fontWeight: "600", 
                      color: result.Result === "Distinction" ? "#28a745" : 
                            result.Result === "Fail" ? "#dc3545" : "#495057"
                    }}>
                      {result["Result"]}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="text-center">
                <Button 
                  variant="primary" 
                  onClick={handleDownloadPDF}
                  style={{
                    padding: "0.75rem 2rem",
                    borderRadius: "0.375rem",
                    fontWeight: "500",
                    backgroundColor: "#5a8dee",
                    borderColor: "#5a8dee",
                    boxShadow: "none",
                    transition: "all 0.2s ease"
                  }}
                  className="hover-effect"
                >
                  <i className="fas fa-download me-2"></i>Download as PDF
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>

      <div style={{ marginTop: "80px" }}>
        <Footer />
      </div>

      <style jsx>{`
        .hover-effect:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
        }
        .table td {
          padding: 0.75rem;
          vertical-align: middle;
        }
      `}</style>
    </>
  );
};

export default ViewResult;